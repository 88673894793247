











import "reflect-metadata";
import Vue from "vue";
import { Component } from "vue-property-decorator";
@Component
export default class Error extends Vue {
}
