<template>
  <div class="component">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
      <path style="fill:#FFE6A1;" d="M479.632,248.36l-24.341,4.868c-8.1,1.62-16.241-2.593-19.596-10.142l-26.073-58.666
        c-2.207-4.967-6.579-8.639-11.851-9.958l-13.583-3.395c-7.86-1.965-13.373-9.026-13.373-17.128V59.803
        c0-4.399-1.643-8.64-4.606-11.892l-32.794-35.986c-29.567-9.363-61.439-13.54-94.575-11.358
        C111.485,8.957,8.316,112.557,0.545,239.95C-8.522,388.608,109.356,512,256.055,512c139.923,0,253.554-112.275,255.89-251.641
        l-20.954-10.477C487.479,248.125,483.482,247.59,479.632,248.36z"/>
      <g>
        <path style="fill:#FFD796;" d="M335.503,432.551c-1.554,0-3.085-0.089-4.632-0.117c-9.7,10.881-23.782,17.772-39.506,17.772
          c-23.16,0-42.796-14.89-49.993-35.598c-31.679-12.537-60.253-31.189-84.267-54.537c-3.633,1.095-7.406,1.859-11.396,1.859
          c-21.939,0-39.724-17.785-39.724-39.724c0-7.662,2.272-14.753,6.03-20.821c-0.269-0.481-0.556-0.948-0.823-1.431
          c-27.555-1.868-49.344-24.753-49.344-52.782c0-16.519,7.566-31.264,19.417-40.978c-1.123-9.731-1.762-19.609-1.762-29.643
          c0-50.349,14.594-97.262,39.697-136.854C47.614,85.085,0.055,164.964,0.055,256c0,141.385,114.615,256,256,256
          c91.036,0,170.914-47.559,216.303-119.145C432.766,417.958,385.853,432.551,335.503,432.551z"/>
        <circle style="fill:#FFD796;" cx="198.676" cy="189.793" r="22.069"/>
        <circle style="fill:#FFD796;" cx="432.607" cy="335.448" r="22.069"/>
        <circle style="fill:#FFD796;" cx="231.779" cy="342.069" r="15.448"/>
        <circle style="fill:#FFD796;" cx="350.952" cy="200.827" r="15.448"/>
        <circle style="fill:#FFD796;" cx="289.159" cy="64" r="15.448"/>
      </g>
      <g>
        <path style="fill:#B97850;" d="M243.209,227.386l-10.205,17.009c-6.1,10.166-0.644,23.336,10.857,26.211l30.376,7.594
          c9.46,2.365,19.046-3.386,21.41-12.846l3.475-13.9c0.703-2.812,0.703-5.752,0-8.564l-3.674-14.697
          c-2.285-9.143-11.349-14.879-20.59-13.031l-19.972,3.994C250.008,220.133,245.769,223.121,243.209,227.386z"/>
        <path style="fill:#B97850;" d="M294.162,336.673l-10.643,17.738c-5.291,8.819-1.955,20.276,7.243,24.875l33.882,16.941
          c10.102,5.052,22.284-0.552,25.024-11.509l3.49-13.96l4.079-16.319c2.539-10.156-4.267-20.279-14.632-21.76l-30.809-4.402
          C304.766,327.273,297.816,330.582,294.162,336.673z"/>
        <path style="fill:#B97850;" d="M108.413,231.358l-12.989,21.649c-4.168,6.947-3.073,15.839,2.655,21.568l20.442,20.443
          c7.441,7.441,19.697,6.762,26.271-1.455l17.525-21.907c3.442-4.302,4.678-9.965,3.341-15.311l-3.997-15.988
          c-1.655-6.62-6.975-11.692-13.665-13.031l-20.982-4.196C119.698,221.666,112.252,224.96,108.413,231.358z"/>
        <path style="fill:#B97850;" d="M180.291,88.019l-10.016,16.694c-5.811,9.685-1.159,22.261,9.556,25.833l21.735,7.245
          c6.344,2.114,13.338,0.463,18.067-4.265l11.699-11.699c4.385-4.385,6.148-10.75,4.644-16.766l-3.06-12.239
          c-1.965-7.861-9.027-13.375-17.129-13.375h-20.358C189.229,79.448,183.482,82.702,180.291,88.019z"/>
        <path style="fill:#B97850;" d="M393.516,224.47l-10.219,10.219c-6.894,6.894-6.894,18.073,0,24.968l5.001,5.001
          c5.374,5.374,13.582,6.706,20.38,3.307l28.917-14.458c7.401-3.701,11.24-12.045,9.233-20.073l-1.31-5.239
          c-2.285-9.143-11.349-14.879-20.59-13.031l-22.389,4.478C399.12,220.324,395.981,222.005,393.516,224.47z"/>
        <path style="fill:#B97850;" d="M327.869,98.456l-11.725,15.634c-4.307,5.742-4.708,13.522-1.015,19.677l10.849,18.081
          c5.186,8.644,16.545,11.198,24.932,5.607l22.03-14.687c8.434-5.622,10.412-17.175,4.331-25.283l-11.976-15.968
          c-2.155-2.873-5.134-5.021-8.541-6.156l-9.178-3.06C340.366,89.896,332.428,92.376,327.869,98.456z"/>
        <path style="fill:#B97850;" d="M176.789,377.385l-11.528,14.41c-7.401,9.251-3.77,23.021,7.23,27.422l15.485,6.194
          c4.21,1.684,8.905,1.684,13.114,0l17.378-6.952c10.412-4.164,14.354-16.855,8.134-26.186l-2.985-4.477
          c-1.705-2.557-4.046-4.623-6.794-5.998l-18.35-9.175C191.024,368.9,181.989,370.885,176.789,377.385z"/>
      </g>
      <g>
        <path style="fill:#A5694B;" d="M140.428,292.921l-20.442-20.442c-5.728-5.728-6.824-14.621-2.655-21.567l12.989-21.649
          c1.108-1.846,2.572-3.345,4.191-4.633l-7.496-1.498c-7.316-1.463-14.763,1.831-18.602,8.228l-12.989,21.649
          c-4.168,6.947-3.073,15.839,2.655,21.568l20.442,20.442c6.897,6.897,17.875,6.731,24.685,0.139
          C142.23,294.511,141.29,293.784,140.428,292.921z"/>
        <path style="fill:#A5694B;" d="M219.248,131.506l-21.735-7.244c-10.714-3.572-15.368-16.148-9.556-25.833l10.016-16.694
          c0.509-0.85,1.182-1.549,1.813-2.287h-4.358c-6.201,0-11.948,3.254-15.139,8.572l-10.016,16.693
          c-5.812,9.685-1.16,22.261,9.556,25.833l21.735,7.245c6.344,2.114,13.338,0.463,18.067-4.265l1.645-1.645
          C220.6,131.741,219.914,131.728,219.248,131.506z"/>
        <path style="fill:#A5694B;" d="M290.988,273.124l-30.376-7.594c-11.502-2.876-16.958-16.045-10.857-26.211l10.205-17.009
          c1.215-2.025,2.829-3.734,4.69-5.105l-9.764,1.953c-4.877,0.975-9.118,3.964-11.677,8.23l-10.205,17.009
          c-6.1,10.166-0.644,23.336,10.857,26.211l30.376,7.594c6.399,1.6,12.799-0.572,17.038-5.035
          C291.179,273.143,291.084,273.148,290.988,273.124z"/>
        <path style="fill:#A5694B;" d="M342.469,150.321L331.62,132.24c-3.693-6.155-3.292-13.933,1.015-19.677L344.36,96.93
          c1.284-1.713,2.878-3.055,4.606-4.168l-1.391-0.463c-7.21-2.403-15.148,0.077-19.708,6.156l-11.724,15.633
          c-4.307,5.743-4.708,13.522-1.015,19.677l10.849,18.081c5.169,8.615,16.466,11.17,24.844,5.653
          C347.464,156.102,344.492,153.692,342.469,150.321z"/>
        <path style="fill:#A5694B;" d="M402.467,263.482c-6.895-6.895-6.895-18.073,0-24.968l10.219-10.219
          c2.464-2.464,5.603-4.145,9.022-4.828l18.845-3.769c-4.032-3.832-9.789-5.703-15.626-4.535l-22.389,4.478
          c-3.418,0.684-6.557,2.364-9.022,4.828l-10.219,10.219c-6.894,6.895-6.894,18.073,0,24.968l5.001,5.001
          c5.041,5.041,12.541,6.362,19.065,3.723L402.467,263.482z"/>
        <path style="fill:#A5694B;" d="M312.633,377.805c-9.198-4.599-12.535-16.056-7.243-24.875l10.643-17.737
          c1.396-2.325,3.369-4.088,5.545-5.517l-9.78-1.397c-7.032-1.005-13.981,2.303-17.635,8.394l-10.643,17.738
          c-5.291,8.819-1.955,20.276,7.243,24.875l33.882,16.941c6.753,3.377,14.374,1.919,19.543-2.643L312.633,377.805z"/>
        <path style="fill:#A5694B;" d="M205.631,422.46l-15.485-6.194c-10.999-4.399-14.63-18.171-7.23-27.422l11.528-14.41
          c0.732-0.915,1.554-1.715,2.422-2.447c-7.106-2.721-15.242-0.647-20.077,5.398l-11.528,14.41c-7.401,9.251-3.77,23.021,7.23,27.422
          l15.485,6.194c4.21,1.684,8.905,1.684,13.114,0l6.177-2.471C206.72,422.781,206.163,422.673,205.631,422.46z"/>
      </g>
      <path style="fill:#C98850;" d="M70.665,361.922c-1.323,0-2.673-0.301-3.939-0.931c-4.362-2.181-6.13-7.482-3.948-11.844
        l8.828-17.655c2.182-4.362,7.47-6.112,11.844-3.948c4.362,2.182,6.13,7.482,3.948,11.844l-8.828,17.655
        C77.023,360.137,73.907,361.922,70.665,361.922z"/>
      <path style="fill:#925F4A;" d="M229.572,476.689h-8.828c-4.875,0-8.828-3.948-8.828-8.828c0-4.879,3.953-8.828,8.828-8.828h8.828
        c4.875,0,8.828,3.948,8.828,8.828C238.4,472.741,234.447,476.689,229.572,476.689z"/>
      <path style="fill:#C98850;" d="M194.253,308.965c-1.323,0-2.673-0.301-3.939-0.931c-4.362-2.182-6.13-7.483-3.948-11.844
        l8.828-17.655c2.177-4.353,7.466-6.112,11.844-3.948c4.362,2.182,6.13,7.482,3.948,11.844l-8.828,17.655
        C200.611,307.181,197.494,308.965,194.253,308.965z"/>
      <g>
        <path style="fill:#925F4A;" d="M141.288,167.724c-1.323,0-2.673-0.301-3.939-0.931l-17.655-8.828
          c-4.362-2.182-6.13-7.482-3.948-11.844c2.177-4.353,7.47-6.121,11.844-3.948L145.245,151c4.362,2.182,6.13,7.482,3.948,11.844
          C147.646,165.939,144.529,167.724,141.288,167.724z"/>
        <path style="fill:#925F4A;" d="M247.228,44.137h-17.655c-4.875,0-8.828-3.948-8.828-8.828s3.953-8.828,8.828-8.828h17.655
          c4.875,0,8.828,3.948,8.828,8.828S252.103,44.137,247.228,44.137z"/>
      </g>
      <path style="fill:#C98850;" d="M282.547,176.551c-3.242,0-6.358-1.784-7.905-4.879l-8.828-17.655
        c-2.182-4.362-0.414-9.664,3.948-11.844c4.371-2.173,9.668-0.405,11.844,3.948l8.828,17.655c2.182,4.362,0.414,9.664-3.948,11.844
        C285.218,176.25,283.87,176.551,282.547,176.551z"/>
      <g>
        <path style="fill:#925F4A;" d="M40.286,219.777c-1.187-0.585-2.264-1.452-3.123-2.577c-2.95-3.884-2.191-9.421,1.694-12.37
          l15.722-11.936c3.881-2.951,9.414-2.185,12.37,1.694c2.95,3.884,2.191,9.421-1.694,12.37l-15.721,11.935
          C46.777,220.985,43.193,221.209,40.286,219.777z"/>
        <path style="fill:#925F4A;" d="M370.814,317.793c-2.259,0-4.518-0.862-6.241-2.586l-17.655-17.655
          c-3.448-3.448-3.448-9.035,0-12.483s9.035-3.448,12.483,0l17.655,17.655c3.448,3.448,3.448,9.035,0,12.483
          C375.331,316.931,373.072,317.793,370.814,317.793z"/>
      </g>
      <path style="fill:#C98850;" d="M388.469,406.069c-2.259,0-4.518-0.862-6.241-2.586c-3.448-3.448-3.448-9.035,0-12.483l8.828-8.828
        c3.448-3.448,9.035-3.448,12.483,0c3.448,3.448,3.448,9.035,0,12.483l-8.828,8.828C392.986,405.207,390.728,406.069,388.469,406.069
        z"/>
      <g>
        <path style="fill:#FFE6A1;" d="M406.124,35.31v22.272c0,6.246,6.307,10.516,12.106,8.196l26.483-10.593
          c3.351-1.341,5.549-4.587,5.549-8.196V35.31c0-4.875-3.953-8.828-8.828-8.828h-26.483C410.077,26.482,406.124,30.435,406.124,35.31
          z"/>
        <path style="fill:#FFE6A1;" d="M424.997,104.904l-5.633,39.434c-1.325,9.276,4.828,17.972,14.015,19.809l16.167,3.233
          c7.016,1.404,14.184-1.566,18.153-7.519l6.08-9.12c3.149-4.725,3.835-10.676,1.841-15.992l-18.417-49.111
          c-2.291-6.108-10.126-7.805-14.739-3.192l-12.472,12.471C427.29,97.618,425.538,101.123,424.997,104.904z"/>
      </g>
      <g>
        <path style="fill:#FFD796;" d="M469.253,151.699l-16.167-3.233c-9.188-1.838-15.34-10.534-14.015-19.809l5.633-39.433
          c0.503-3.517,2.143-6.729,4.529-9.343c-2.396-0.121-4.856,0.65-6.769,2.562l-12.473,12.472c-2.701,2.701-4.454,6.206-4.993,9.987
          l-5.633,39.433c-1.325,9.277,4.828,17.972,14.015,19.809l16.167,3.233c7.015,1.402,14.184-1.566,18.153-7.519l5.319-7.978
          C471.767,151.905,470.514,151.952,469.253,151.699z"/>
        <path style="fill:#FFD796;" d="M435.885,48.122c-5.799,2.319-12.106-1.951-12.106-8.196V26.482h-8.828
          c-4.875,0-8.828,3.953-8.828,8.828v22.272c0,6.246,6.307,10.516,12.106,8.196l26.483-10.593c3.351-1.341,5.549-4.586,5.549-8.196
          v-4.617L435.885,48.122z"/>
        <path style="fill:#FFD796;" d="M472.318,192.523l-5.218,10.436c-1.242,2.485-1.242,5.41,0,7.895l2.381,4.764
          c2.375,4.749,8.374,6.343,12.792,3.398l14.231-9.487c2.456-1.638,3.931-4.394,3.931-7.345v-9.511c0-6.025-5.903-10.28-11.619-8.374
          l-11.395,3.798C475.201,188.836,473.365,190.428,472.318,192.523z"/>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      </svg>

      <h3>
        COOKIE !
      </h3>

      <p>
        We use cookies to make your experience on this website better.
      </p>

      <vs-button @click="accept" block dark>
        I Like Cookies
      </vs-button>
  </div>
</template>
<script>
export default {
  data: () => ({
    count: 0
  }),
  methods: {
    accept() {
      this.$parent.close();
      this.$cookies.set("isAllowedCookie", true, new Date(Date.now + 1000 * 30 * 30));
      console.log("Accepted cookie policy!");
    }
  }
}
</script>
<style scoped>
.component {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.component h3 {
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  font-size: 1.1rem;
}
.component p {
  font-size: 0.8rem;
  line-height: 1rem;
  opacity: 0.7;
}
svg {
  max-width: 100px;
}
</style>