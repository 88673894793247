



































import "reflect-metadata";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
@Component
export default class NavBar extends Vue {
  active: string = 'primary';

  async login() {
    await this.$auth.login();
  }
  logout() {
    this.$auth.logout();
    this.$router.push({ path: "/" });
  }
  goToProfile() {
    this.$router.push({ path: "/profile" });
  }
  @Watch('$route', { immediate: true, deep: true })
  onRouteChange (to: Route, from: any) {
    console.log(to);
    if (to.name) this.active = to.name;
  }
}
