


















import "reflect-metadata";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import NavBar from "./components/NavBar.vue";
import Error from "./components/Error.vue";
import Footer from "./components/Footer.vue";
import vsDivider from "./components/vsDivider.vue";
import cookie from "./components/Cookie.vue";
@Component({
  components: {
    NavBar,
    Error,
    "app-footer": Footer,
    "vs-divider": vsDivider
  },
})
export default class App extends Vue {

  metaInfo = {
    title: '',
    titleTemplate: 'Vein Gallery | %s'
  }
  async created() 
  {
    this.$loader.close(); 
    if (!this.$cookies.get("isAllowedCookie")) {
      this.openNotificationCookie();
    }
  }
  async mouted() {
    this.$loader.close(); 
  }

  openNotificationCookie() {
    this.$vs.notification({
      duration: 'none',
      content: cookie,
    });
  }
}
